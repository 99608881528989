* { box-sizing: border-box; }
body { font-family: calibri; }

.nav-link { cursor: pointer; }

.calculations {
    display: flex; justify-content: space-between;

    table {
        tr {
            body:not(.show-charges) &.hide { display: none; }
        }
        td {
            //padding: 0px 15px;
            &:nth-child(2) { text-align: right; }
            &.profit { color: darkslategrey; }
            &.loss { color: red; }

            .sellPrice {
                cursor: pointer;
                &:hover { text-decoration: underline; }
                & + .sellPrice {
                    &:before { padding-left: 10px; margin-right: 10px; content: "|"; }
                }
            }
        }
    }
}

input[type="range"] { min-width: 300px; }

.charges-toggle {
    position: relative;
    .btn-charges {
        position: absolute; right: 0; top: 10px; color: #f1f1f1; cursor: pointer; text-transform: uppercase; font-size: 13px; user-select: none; font-weight: 500;
        .show-charges & { color: black; }
    }
}